<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="450"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-6 pt-3 pr-6 w-crud-form">
            <template>                
                <v-row justify="center">
                    <v-expansion-panels popout>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">Datos personales</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <template>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field 
                                                ref="dni"
                                                v-model="employeeForm.dni" 
                                                label="DNI" 
                                                outlined 
                                                :rules="[() => !!employeeForm.dni || 'Es necesario ingresar el dni']"
                                                hide-details="auto"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field 
                                                ref="first_name"
                                                v-model="employeeForm.first_name" 
                                                label="Nombres" 
                                                outlined 
                                                :rules="[() => !!employeeForm.first_name || 'Es necesario ingresar los nombres']"
                                                hide-details="auto"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field 
                                                ref="last_name"
                                                v-model="employeeForm.last_name" 
                                                label="Apellidos" 
                                                outlined 
                                                :rules="[() => !!employeeForm.last_name || 'Es necesario ingresar los apellidos']"
                                                hide-details="auto"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu
                                                v-model="menu1"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-model="employeeForm.birthday"
                                                    label="Fecha Nacimiento"
                                                    readonly
                                                    clearable
                                                    v-on="on"
                                                    hide-details
                                                    outlined                                  
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="employeeForm.birthday" @input="menu1 = false" no-title>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="employeeForm.phone" label="Teléfono" outlined hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="employeeForm.email" label="Email" outlined hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">Datos de usuario</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <template>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-switch 
                                                v-model="employeeForm.has_access" 
                                                class="mt-0" 
                                                label="Acceso al sistema"
                                                :true-value="true"
                                                :false-value="false"
                                                hide-details
                                                @change="handleChangerHasAccess"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employeeForm.has_access">
                                        <v-col cols="12">
                                            <v-autocomplete
                                                ref="role_id"
                                                v-model="employeeForm.role_id"
                                                :items="roles"
                                                label="Seleccione rol"                            
                                                outlined
                                                :item-text="customText"
                                                item-value="id"
                                                clearable
                                                hide-details="auto"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employeeForm.has_access">
                                        <v-col cols="12">
                                            <v-text-field 
                                                v-model="employeeForm.username" 
                                                label="Nombre de usuario" 
                                                outlined 
                                                hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employeeForm.has_access">
                                        <v-col cols="12">
                                            <v-text-field 
                                                v-model="employeeForm.password" 
                                                label="Contraseña" 
                                                outlined
                                                type="password"
                                                hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employeeForm.has_access">
                                        <v-col cols="12">
                                            <v-text-field 
                                                v-model="employeeForm.pin_unlock" 
                                                label="Pin desbloqueo"
                                                outlined
                                                maxlength="4"
                                                hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">Datos de trabajador</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <template>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-switch 
                                                v-model="employeeForm.is_worker" 
                                                class="mt-0" 
                                                label="Trabajador"
                                                :true-value="true"
                                                :false-value="false"
                                                hide-details
                                                @change="handleChangerIsWorker"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employeeForm.is_worker">
                                        <v-col cols="12">
                                            <v-combobox
                                                ref="type"
                                                v-model="employeeForm.payment_type"
                                                label="Tipo de pago"
                                                :items="payments_types"
                                                outlined
                                                hide-details="auto">
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employeeForm.is_worker && employeeForm.payment_type === 'SALARIO'">
                                        <v-col cols="12">
                                            <v-text-field 
                                                v-model="employeeForm.salary" 
                                                label="Salario"
                                                outlined
                                                type="number"
                                                prefix="S/"
                                                hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employeeForm.is_worker && employeeForm.payment_type === 'COMISIONES'">
                                        <v-col cols="12">
                                            <v-text-field 
                                                v-model="employeeForm.commission" 
                                                label="Porcentaje de comisión"
                                                outlined
                                                type="number"
                                                prefix="%"
                                                hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employeeForm.is_worker && employeeForm.payment_type === 'COMISIONES'">
                                        <v-col cols="12">
                                            <v-text-field 
                                                v-model="employeeForm.commission_base" 
                                                label="Base"
                                                outlined
                                                type="number"
                                                prefix="S/"
                                                hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">Sucursales</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-treeview
                                    selectable
                                    selected-color="blue"
                                    :items="offices_availables"
                                    open-on-click
                                    v-model="employeeForm.offices"
                                ></v-treeview>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </template>
        </div>

        <div class="mt-2 pl-3 pt-2 pr-3">
            <template>
                <v-row>
                    <v-col cols="6" class="pt-1 pb-1">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="pt-1 pb-1">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState} from 'vuex'

export default {
    name: 'CreateUpdate',
    data() {
        return {
            drawer: false,
            titleForm: null,
            action: null,
            menu1: false,
            has_user: false,
            payments_types: ['SALARIO', 'COMISIONES'],
            employeeForm: {
                id: null,
                dni: null, 
                first_name: null,
                last_name: null,
                birthday: null,
                email: null,
                phone: null,
                role_id: null,
                username: null,
                password: null,
                pin_unlock: null,
                is_worker: false,
                payment_type: null,
                salary: null,
                commission: null,
                commission_base: null,
                has_access: false,
                offices: []
            }
        }
    },
    computed: {
        ...mapState('offices', [
            'offices_availables'
        ]),
        ...mapState('roles', [
            'roles'
        ])
    },
    methods: {
        ...mapActions('employees', [
            'store',
            'update'
        ]),
        ...mapActions('offices', [
            'getAvailables'
        ]),        
        async showForm(title, action, employee = null) {
            await this.clearData();
            this.titleForm = title
            this.action = action
            if (employee) {
                this.setEmployee(employee)
            }
            this.drawer = !this.drawer
        },
        clearData() {
            this.titleForm = null
            this.action = null
            this.employeeForm.id = null
            this.employeeForm.dni = null
            this.employeeForm.first_name = null
            this.employeeForm.last_name = null
            this.employeeForm.birthday = null
            this.employeeForm.email = null
            this.employeeForm.phone = null
            this.employeeForm.role_id = null
            this.employeeForm.username = null
            this.employeeForm.password = null
            this.employeeForm.pin_unlock = null;
            this.employeeForm.is_worker = false;
            this.employeeForm.payment_type = null;
            this.employeeForm.salary = null;
            this.employeeForm.commission = null;
            this.employeeForm.commission_base = null;
            this.employeeForm.has_access = false;
            this.employeeForm.offices = []
            this.clearErrors();
        },
        setEmployee(employee) {
            this.employeeForm.id = employee.id
            this.employeeForm.dni = employee.dni
            this.employeeForm.first_name = employee.first_name
            this.employeeForm.last_name = employee.last_name
            this.employeeForm.birthday = employee.birthday
            this.employeeForm.email = employee.email
            this.employeeForm.phone = employee.phone            
            if (employee.user) {
                this.employeeForm.username = employee.user.username;
                this.employeeForm.pin_unlock = employee.user.pin_unlock;
                this.employeeForm.role_id = parseInt(employee.user.role_codes);
            } else {
                this.employeeForm.username = null;
                this.employeeForm.pin_unlock = null;
            }
            this.employeeForm.has_access = employee.has_access;
            this.employeeForm.is_worker = employee.is_worker;
            this.employeeForm.payment_type = employee.payment_type;
            this.employeeForm.salary = employee.salary;
            this.employeeForm.commission = employee.commission;
            this.employeeForm.commission_base = employee.commission_base;
            
            if (employee.office_codes) {
                this.employeeForm.offices = employee.office_codes
            } else {
                this.employeeForm.offices = []
            }
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleChangerHasAccess (value) {
            if (!value) {
                this.employeeForm.role_id = null;
                this.employeeForm.username = null;                
                this.employeeForm.password = null;
                this.employeeForm.pin_unlock = null;
            }
        },
        handleChangerIsWorker (value) {
            if (!value) {
                this.employeeForm.payment_type = null;
                this.employeeForm.salary = null;
                this.employeeForm.commission = null;
                this.employeeForm.commission_base = null;
            }
        },
        handleCancel() {
            this.closeForm()
        },
        async handleStore() {
            if (!this.existErrors()) {
                await this.store(this.employeeForm)
                this.closeForm()
            }            
        },
        async handleUpdate() {
            if (!this.existErrors()) {
                await this.update(this.employeeForm)
                this.closeForm()
            }            
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.employeeForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.employeeForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.employeeForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>

</style>