<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="450"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-6 pt-3 pr-6 w-crud-form">                
            <template>                
                <v-row justify="center">
                    <v-expansion-panels popout>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">Datos personales</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <template>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="filters.dni" label="DNI" outlined hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="filters.first_name" label="Nombres" outlined hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="filters.last_name" label="Apellidos" outlined hide-details></v-text-field>
                                        </v-col>
                                    </v-row>                                    
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="filters.phone" label="Teléfono" outlined hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="filters.email" label="Email" outlined hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">Datos de usuario</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <template>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="filters.username" label="Nombre de usuario" outlined hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">Sucursales</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-treeview
                                    selectable
                                    selected-color="blue"
                                    :items="offices_availables"
                                    open-on-click
                                    v-model="filters.offices"
                                ></v-treeview>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </template>                  
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState} from 'vuex'

export default {
    name: 'FiltersPaginated',
    data() {
        return {
            drawer: false,
            titleForm: null,
            menu1: false,
            filters: {
                dni: null, 
                first_name: null,
                last_name: null,
                birthday: null,
                email: null,
                phone: null,
                username: null,
                password: null,
                offices: []
            }
        }
    },
    computed: {
        ...mapState('offices', [
            'offices_availables'
        ])
    },    
    methods: {
        ...mapActions('offices', [
            'getAvailables'
        ]),
        ...mapActions('employees', [
            'getEmployees',
            'changeFilterPaginated'
        ]),
        showForm(title) {
            this.titleForm = title
            this.drawer = !this.drawer
        },
        closeForm() {
            this.drawer = false
        },
        clearData() {
            this.filters.dni = null
            this.filters.first_name = null
            this.filters.last_name = null
            this.filters.birthday = null
            this.filters.email = null
            this.filters.phone = null
            this.filters.username = null
            this.filters.password = null
            this.filters.offices = []
          this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
        },
        customText(item){
            let text = item.name
            
            return text
        },
        async handleClearFilters() {
            this.clearData()

            await this.getEmployees({...this.filters,paginated:true,itemsPerPage:10})
        },
        async handleSearch() {
          this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
            await this.getEmployees({...this.filters,paginated:true,itemsPerPage:10})
            this.closeForm()
        }
    }
}
</script>

<style scoped>

</style>