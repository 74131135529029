<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Empleados</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('empleados.create')" dark color="#023145" width="140" class="float-right"
                 @click="handleShowCreate">
            Nuevo
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-1">
          <v-text-field
              v-model="search_employee"
              label="Buscar"
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo>
          </v-text-field>
        </v-col>
        <v-col cols="6" class="pt-1">
          <v-btn class="w-btn-search" height="40" @click="handleSearch">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('empleados.filters')" dark color="#023145" outlined width="140"
                 class="float-right" @click="handleShowFilters">
            <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
            Ver Filtros
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
                v-model="selectedRows"
                :headers="headers"
                :items="employees"
                :items-per-page="10"
                class="elevation-1 w-table"
                height="57vh"
                show-select
                :single-select="true"
                @item-selected="handleSelectItem"
                :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait">
              <template v-slot:top>
                                <span class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todos los empleados
                                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.fullname="{ item }">
                <span class="font-weight-medium">{{ item.fullname }}</span>
                <span v-if="item.active" class="activo">
                                    <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                                    activo
                                </span>
                <span v-else class="inactivo">
                                    <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                                    inactivo
                                </span>
                <br>
                <v-icon v-if="item.email" class="mr-1" size="13" color="#303197">fas fa-envelope</v-icon>
                <span class="font-italic email-company">
                                    {{ item.email }}
                                </span>
              </template>

              <template v-slot:item.has_access="{item}">
                <span v-if="item.has_access">SI</span>
                <span v-else>NO</span>
              </template>

              <template v-slot:item.username="{item}">
                <span v-if="item.user">{{ item.user.username }}</span>
              </template>

              <template v-slot:item.role_name="{item}">
                                <span v-if="item.user">
                                    {{ item.user.role_names }}
                                </span>
              </template>

              <template v-slot:item.payment_type="{item}">
                                <span v-if="item.payment_type === 'COMISIONES'">
                                    Comisión: {{ item.commission }} %
                                </span>
                <span v-else-if="item.payment_type === 'SALARIO'">
                                    Salario: {{ item.salary | currency('S/') }}
                                </span>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <div class="text-center">
      <v-bottom-sheet v-model="show_options_crud" :hide-overlay="true" persistent :retain-focus="false">
        <v-sheet class="text-center" height="85px">
          <div class="py-3">
            <v-btn v-if="this.$hasPermision('empleados.edit')" class="mt-3" dark color="blue" outlined
                   @click="handleEdit">
              <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
              Editar
            </v-btn>
            <v-btn v-if="show_btn_active && this.$hasPermision('empleados.change_status')" class="ml-4 mt-3" dark
                   color="green" outlined @click="handleChangeStatus">
              <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
              Activar
            </v-btn>
            <v-btn v-if="!show_btn_active && this.$hasPermision('empleados.change_status')" class="ml-4 mt-3" dark
                   color="amber" outlined @click="handleChangeStatus">
              <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
              Inactivar
            </v-btn>
            <v-btn v-if="this.$hasPermision('empleados.destroy')" class="ml-4 mt-3" dark color="red" outlined
                   @click="handleDelete">
              <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
              Eliminar
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
    <create-update ref="createUpdate"></create-update>
    <Filters ref="filters"></Filters>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import OfficeLogin from '../../../components/auth/OfficeLogin';
import ArrayTools from '../../../helpers/ArrayTools'
import CreateUpdate from '../../../components/employees/CreateUpdate'
import Filters from '../../../components/employees/FiltersPaginated'

export default {
  name: 'Employees',
  components: {
    CreateUpdate,
    Filters,
    OfficeLogin
  },
  data() {
    return {
      search_employee: null,
      drawer: false,
      show_options_crud: false,
      show_btn_active: false,
      selectedRows: [],
      itemsBreadcrumbs: [
        {
          text: 'Seguridad',
          disabled: true,
          href: '',
        }
      ],
      headers: [
        {text: 'NOMBRES Y APELLIDOS', value: 'fullname', sortable: false},
        {text: 'DNI', value: 'dni', sortable: false},
        {text: 'FEC. NACIMIENTO', value: 'birthday', sortable: false},
        {text: 'TELÉFONO', value: 'phone', sortable: false},
        {text: 'ACCESO AL SISTEMA', value: 'has_access', sortable: false},
        {text: 'USUARIO', value: 'username', sortable: false},
        {text: 'ROL', value: 'role_name', sortable: false},
        {text: 'TIPO DE PAGO', value: 'payment_type', sortable: false},
      ],
      loading: false,
      dataTable: {page: 1},
      options: {},
      pageSelected: 1,
      perPage: 10,
    }
  },
  computed: {
    ...mapState('employees', [
      'employees',
      'paginated',
      'filtersPaginated'
    ])
  },
  watch: {
    options: {
      handler() {
        // console.log(this.options)
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        const search = this.search_employee
        if (this.filtersPaginated.dni != null || this.filtersPaginated.first_name != null ||
            this.filtersPaginated.last_name != null || this.filtersPaginated.birthday != null
            || this.filtersPaginated.email != null || this.filtersPaginated.phone
            || this.filtersPaginated.username != null || this.filtersPaginated.offices.length > 0
        ) {
          this.getAllEmployees({
            paginated: true, page: this.pageSelected,
            itemsPerPage: this.perPage,
            dni: this.filtersPaginated.dni,
            first_name: this.filtersPaginated.first_name,
            last_name: this.filtersPaginated.last_name,
            birthday: this.filtersPaginated.birthday,
            email: this.filtersPaginated.email,
            phone: this.filtersPaginated.phone,
            username: this.filtersPaginated.username,
            offices: this.filtersPaginated.offices,
          })
        } else {
          if (search != null && search.trim().length > 0) {
            this.getAllEmployees({
              paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
              fullname: search
            })
          } else {
            this.getAllEmployees({paginated: true, page: this.pageSelected, itemsPerPage: this.perPage})

          }
        }

      },
      deep: true,
    }
  },
  mounted() {
    this.changeFilterPaginated({
      dni: null,
      first_name: null,
      last_name: null,
      birthday: null,
      email: null,
      phone: null,
      username: null,
      password: null,
      offices: []
    })
  },
  methods: {
    ...mapActions('employees', [
      'getEmployees',
      'change_status',
      'delete',
      'changeFilterPaginated'
    ]),
    ...mapActions('offices', [
      'getAvailables'
    ]),
    ...mapActions('roles', [
      'getRoles'
    ]),
    showOptionCrud() {
      this.show_options_crud = this.selectedRows.length > 0
      if (this.show_options_crud) {
        this.show_btn_active = !this.selectedRows[0].active
      }
    },
    handleSelectItem(selected) {
      if (selected.value) {
        this.selectedRows.push(selected.item)
      } else {
        let index_found = ArrayTools.getIndexById(this.selectedRows, selected.item.id)
        if (index_found > -1) {
          this.selectedRows.splice(index_found, 1)
        }
      }
      this.showOptionCrud()
    },
    handleShowCreate() {
      this.$refs.createUpdate.showForm('Nuevo empleado', 'create')
    },
    handleEdit() {
      let employee = this.selectedRows[0]
      this.selectedRows = []
      this.showOptionCrud()
      this.$refs.createUpdate.showForm('Modificar datos de empleado', 'update', employee)
    },
    handleShowFilters() {
      this.$refs.filters.showForm('Filtrar datos de empleados')
    },
    async getAllEmployees(filters) {
      this.loading = true
      await this.getEmployees(filters)
          .then(res => this.loading = false)
          .catch(err => this.loading = false)
    },
    async handleSearch() {
      this.selectedRows = []
      this.showOptionCrud()
      this.changeFilterPaginated({
        dni: null,
        first_name: null,
        last_name: null,
        birthday: null,
        email: null,
        phone: null,
        username: null,
        password: null,
        offices: []
      })
      let filters = {
        fullname: this.search_employee
      }
      this.loading = true
      await this.getEmployees({...filters,paginated:true,itemsPerPage:10})
          .then(res => this.loading = false)
          .catch(err => this.loading = false)
    },
    async handleChangeStatus() {
      let employee = this.selectedRows[0]
      this.selectedRows = []
      this.showOptionCrud()
      await this.change_status(employee)
    },
    async handleDelete() {
      let employee = this.selectedRows[0]
      await this.delete(employee)
      this.selectedRows = []
      this.showOptionCrud()
    }
  },
  created() {
    this.getAllEmployees({paginated: true});
    this.getAvailables();
    this.getRoles();
  }
}
</script>

<style scoped>

</style>